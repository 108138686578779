export const HomeIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 22 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.251 9.215c0 .457.352.852.914.852.272 0 .519-.149.738-.325l8.72-7.321c.245-.211.526-.211.773 0l8.71 7.321c.21.176.456.325.729.325.518 0 .914-.325.914-.835a.907.907 0 00-.343-.73l-2.47-2.073V2.772c0-.395-.254-.641-.65-.641h-1.213c-.387 0-.65.246-.65.641v1.539L12.257.813C11.492.17 10.525.17 9.76.813L.603 8.503a.919.919 0 00-.352.712zm2.856 7.901c0 1.266.791 2.03 2.101 2.03h11.593c1.3 0 2.1-.764 2.1-2.03v-6.68l-7.356-6.16c-.343-.3-.756-.29-1.09 0l-7.348 6.16v6.68zm10.204.378H8.698V11.87c0-.413.273-.677.686-.677h3.252c.413 0 .677.264.677.677v5.625z" />
  </svg>
);

export const LocationIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 19 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.984 9.77l6.54.027c.131 0 .175.053.175.184l.018 6.496c0 1.511 1.89 1.854 2.557.395l6.654-14.396C18.64.938 17.444-.082 15.977.595L1.51 7.265c-1.345.616-1.064 2.497.474 2.506z" />
  </svg>
);
