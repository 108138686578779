const workerMobilePrefix = "Worker Mobile:";

export const MAP_EVENTS = {
  TAPPED_MAP_HOME_BUTTON: `${workerMobilePrefix} Map Home Button Tapped`,
  TAPPED_MAP_LOCATION_BUTTON: `${workerMobilePrefix} Map Location Button Tapped`,
  REQUESTED_LOCATION_PERMISSION: `${workerMobilePrefix} Location Permission Requested`,
  GRANTED_LOCATION_PERMISSION: `${workerMobilePrefix} Location Permission Granted`,
  DECLINED_LOCATION_PERMISSION: `${workerMobilePrefix} Location Permission Denied`,
  TAPPED_MAP_EMPTY_STATE: `${workerMobilePrefix} Map Empty State Tapped`,
  DISPLAYED_MAP_EMPTY_STATE: `${workerMobilePrefix} Map Empty State Displayed`,
};
