import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonContent, IonLoading } from "@ionic/react";
import { Box, DialogContent } from "@mui/material";
import { ShiftFilters } from "@src/app/components/shiftFilters";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { FullScreenDialog, SlideUpTransition, useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { convertToGeoLocation } from "@src/appV2/Location";
import { calculateEstimatedDrivingDistanceInMiles } from "@src/appV2/Location/utils/geoDistance";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES,
  HCF_PROFILE_SOURCE,
  SEARCH_MODE,
  USER_EVENTS,
} from "@src/constants";
import { Facility } from "@src/lib/interface";
import { computeShiftsCountMap } from "@src/utils/openShifts";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";

import { fetchFacilityOpenShiftCount } from "./api";
import { FETCH_FACILITY_OPEN_SHIFT_COUNT_ENDPOINT } from "./api/api";
import { GoogleMapShifts } from "./map";
import { RequestOpenShiftOptionsMap } from "./model";
import { FacilityDetails } from "../dayView/facilityDetails";

interface OpenShiftMapInterface {
  segmentView: string;
}

export function OpenShiftMap(props: OpenShiftMapInterface) {
  const { segmentView } = props;

  const { setFilters } = useAgentPreferences();

  const worker = useDefinedWorker();

  const { showErrorToast } = useToast();

  const [facilityDetails, setFacilityDetails] = useState<Facility>();
  const facilityDetailsModalState = useModalState();

  const [currentMapCenterCoordinates, setCurrentMapCenterCoordinates] = useState<number[]>(
    worker.geoLocation?.coordinates ?? []
  );
  const isMapView = segmentView === "map";
  const distancePreference = worker.preference?.distance ?? DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES;
  const qualificationPreference = worker.preference?.qualification ?? "";

  const openMapFacilityDetails = (facility: Facility) => {
    logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
      shifts: facility.shiftsCount || 0,
    });
    setFacilityDetails(facility);
    facilityDetailsModalState.openModal();
  };

  const closeFacilityDetails = () => {
    setFacilityDetails(undefined);
    facilityDetailsModalState.closeModal();
  };

  const dateRange = {
    start: moment().add(1, "minutes").format("YYYY-MM-DD"),
    end: moment().add(30, "days").format("YYYY-MM-DD"),
  };
  const apiOptions: RequestOpenShiftOptionsMap = {
    range: dateRange,
    coordinates: currentMapCenterCoordinates ?? [],
    distance: distancePreference,
    qualification: qualificationPreference,
    specialities: worker.specialities,
  };

  const { data: facilityShifts = [], isLoading: facilityShiftsAreLoading } = useQuery<
    Facility[],
    Error
  >({
    queryKey: [FETCH_FACILITY_OPEN_SHIFT_COUNT_ENDPOINT, apiOptions],
    queryFn: () => fetchFacilityOpenShiftCount(apiOptions),
    onError: () => {
      showErrorToast("Failed to fetch map openShifts data, please try again");
    },
    enabled: isMapView,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_MAP_VIEW_FACILITY_OPEN_SHIFT_COUNT_FAILURE,
    },
  });

  useLogEffect(USER_EVENTS.VIEW_OPEN_SHIFT_MAP);

  const onClickFindShiftsButton = (coordinates: number[]): void => {
    setCurrentMapCenterCoordinates(coordinates);
  };

  const getSearchMode = () => {
    if (!facilityDetails?.geoLocation) {
      return;
    }
    const facilityGeoLocation = convertToGeoLocation(facilityDetails.geoLocation.coordinates);
    const agentGeoLocation = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

    const distanceInMiles = calculateEstimatedDrivingDistanceInMiles(
      agentGeoLocation,
      facilityGeoLocation
    );
    if (distanceInMiles > DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES) {
      return SEARCH_MODE.NON_QFA_RANGE_SHIFTS;
    }
  };

  return (
    <>
      <IonContent data-testid="open-shifts-map">
        <IonLoading isOpen={facilityShiftsAreLoading && isMapView} backdropDismiss={true} />
        <div className="open-shift-map-container">
          <Box paddingY={1}>
            <ShiftFilters
              totalShifts={computeShiftsCountMap(facilityShifts)}
              distancePreference={distancePreference}
              qualificationPreference={qualificationPreference}
              searchMode={SEARCH_MODE.MAP}
              facilityShiftIsLoading={facilityShiftsAreLoading}
              setFilters={setFilters}
              agentLicenses={worker.licensesData}
            />
          </Box>
          <GoogleMapShifts
            segmentView={segmentView}
            onClickHcfMarker={openMapFacilityDetails}
            facilityShiftsAreLoading={facilityShiftsAreLoading}
            facilityShifts={facilityShifts}
            onClickFindShiftsButton={onClickFindShiftsButton}
            currentMapCenterCoordinates={currentMapCenterCoordinates}
          />
        </div>
      </IonContent>

      {isDefined(facilityDetails) ? (
        <FullScreenDialog
          modalState={facilityDetailsModalState}
          TransitionComponent={SlideUpTransition}
        >
          <DialogContent>
            <FacilityDetails
              facility={facilityDetails as Facility}
              onClose={closeFacilityDetails}
              shift={{}}
              hcfProfileSource={HCF_PROFILE_SOURCE.MAP}
              searchMode={getSearchMode()}
            />
          </DialogContent>
        </FullScreenDialog>
      ) : null}
    </>
  );
}
