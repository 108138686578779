import { isDefined } from "@clipboard-health/util-ts";
import { Marker } from "@react-google-maps/api";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { Facility } from "@src/lib/interface";
import { memo } from "react";

import { markersSourcePath } from "../googleMapShifts.const";

const emptyShiftHcfIcon = `${markersSourcePath}/emptyShiftHcf.png`;
const priorityShiftIcon = `${markersSourcePath}/priorityShift.png`;
const shiftIcon = `${markersSourcePath}/shiftHcf.png`;

interface Props {
  facilityShifts: Facility[];
  onClickMarker: (facility: Facility) => void;
}

function FacilitiesMarkers(props: Props) {
  const { facilityShifts, onClickMarker } = props;
  const { isWorkerAteamEnabled } = useATeamStatus();

  const getShiftIcon = (facility: Facility) => {
    if (facility.priorityAccessShiftsCount) {
      return priorityShiftIcon;
    }

    if (
      isWorkerAteamEnabled &&
      isDefined(facility.windows?.[ShiftWindow.A_TEAM]) &&
      (facility.windows?.[ShiftWindow.A_TEAM] ?? -1) > 0
    ) {
      return priorityShiftIcon;
    }

    return shiftIcon;
  };
  return (
    <>
      {facilityShifts.map((facility) => {
        if ((facility?.shiftsCount as number) < 1) {
          return (
            <Marker
              key={facility._id}
              onClick={() => onClickMarker(facility)}
              icon={emptyShiftHcfIcon}
              position={{
                lng: facility?.geoLocation?.coordinates[0] as number,
                lat: facility?.geoLocation?.coordinates[1] as number,
              }}
              zIndex={facility.shiftsCount}
            />
          );
        }
        return (
          <Marker
            key={facility._id}
            onClick={() => onClickMarker(facility)}
            icon={getShiftIcon(facility)}
            label={{
              text: `${facility.shiftsCount}`,
              color: "white",
              fontSize: "0.75em",
              fontWeight: "bold",
            }}
            position={{
              lng: facility?.geoLocation?.coordinates?.[0] ?? 0,
              lat: facility?.geoLocation?.coordinates?.[1] ?? 0,
            }}
            zIndex={facility.shiftsCount}
          />
        );
      })}
    </>
  );
}

export const MemoizedFacilitiesMarkers = memo(FacilitiesMarkers);
